import React from 'react';
import { Grid, Paper, Typography, Box, Tooltip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip as RechartsTooltip } from 'recharts';
import InfoIcon from '@mui/icons-material/Info';
import { BezirkStat } from '../types';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28']; 

interface BezirkPieChartProps {
    data: BezirkStat[];
}

const BezirkPieChart: React.FC<BezirkPieChartProps> = ({ data }) => {
    // Don't render the chart if there's no data
    if (!data || data.length === 0) {
        return (
            <Grid item xs={12} md={6}>
                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6">Bestellungen nach Bezirk</Typography>
                    <Typography>Keine Daten verfügbar</Typography>
                </Paper>
            </Grid>
        );
    }

    // Calculate totals for the financial table
    const totalBestellungen = data.reduce((sum, item) => sum + item.value, 0);
    const totalAusgaben = data.reduce((sum, item) => sum + item.ausgaben, 0);

    return (
        <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, height: '100%', minHeight: 300 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Bestellungen nach Bezirk</Typography>
                    <Tooltip title="Zeigt die Verteilung der Bestellungen nach Bezirk (Enzkreis, Stadt, Andere) mit entsprechenden Ausgaben">
                        <IconButton size="small">
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
                
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ResponsiveContainer width="100%" height={200}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={true}
                                    label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Legend />
                                <RechartsTooltip 
                                    formatter={(value: number, name: string) => [`${value} Bestellungen`, name]}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Bezirk</TableCell>
                                        <TableCell align="right">Anzahl</TableCell>
                                        <TableCell align="right">Ausgaben (€)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item) => (
                                        <TableRow key={item.name}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell align="right">{item.value}</TableCell>
                                            <TableCell align="right">{item.ausgaben.toLocaleString('de-DE')}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Gesamt</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{totalBestellungen}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{totalAusgaben.toLocaleString('de-DE')}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default BezirkPieChart; 