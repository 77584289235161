import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Button, Tab, Tabs, CircularProgress, TextField, Switch, FormControlLabel, Select, MenuItem as MuiMenuItem, InputLabel, FormControl, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { supabase } from '../supabaseClient';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

interface PageContent {
  id: number;
  page_key: string;
  title: string;
  content: string;
  header_image_path?: string;
  is_system?: boolean;
}

// Menu item interface
interface MenuItem {
  id?: number;
  title: string;
  type: 'page' | 'url' | 'custom';
  value: string;
  order_position: number;
}

// Add constant for pages that can have header images
const PAGES_WITH_HEADER = ['about', 'aktuelles'];

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false
  }
};

const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'link'
];

const Settings: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('aktuelles');
  const [pages, setPages] = useState<PageContent[]>([]);
  const [customPages, setCustomPages] = useState<PageContent[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState<string | null>(null);
  const [showOnHomepage, setShowOnHomepage] = useState(false);
  
  // Menu item states
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [editingMenuItem, setEditingMenuItem] = useState<MenuItem | null>(null);
  const [menuItemFormOpen, setMenuItemFormOpen] = useState(false);
  const [menuTitle, setMenuTitle] = useState('Menü');
  
  // Custom page states
  const [isAddingCustomPage, setIsAddingCustomPage] = useState(false);
  const [newCustomPage, setNewCustomPage] = useState<Partial<PageContent>>({
    title: '',
    content: '',
    page_key: '',
    is_system: false
  });

  // Add a new state for the menu switch
  const [showCustomMenu, setShowCustomMenu] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      await fetchPages();
      if (selectedTab === 'aktuelles') {
        await fetchHomepageFlag();
      } else if (selectedTab === 'menu') {
        await fetchMenuItems();
        await fetchMenuTitle();
        await fetchMenuSwitch();
      } else if (selectedTab === 'custom') {
        await fetchCustomPages();
      }
      setLoading(false);
    };
    loadInitialData();
  }, [selectedTab]);

  useEffect(() => {
    // Reset header image URL when switching tabs
    setHeaderImageUrl(null);
    
    // Only try to load header image for pages that support it
    if (PAGES_WITH_HEADER.includes(selectedTab)) {
      const currentPage = pages.find(p => p.page_key === selectedTab);
      if (currentPage?.header_image_path) {
        const url = supabase.storage
          .from('page-images')
          .getPublicUrl(currentPage.header_image_path).data.publicUrl;
        setHeaderImageUrl(url);
      }
    }
  }, [selectedTab, pages]);

  useEffect(() => {
    if (selectedTab === 'aktuelles') {
      fetchHomepageFlag();
    }
  }, [selectedTab]);

  const fetchPages = async () => {
    try {
      const { data, error } = await supabase
        .from('page_content')
        .select('*')
        .eq('is_system', true);

      if (error) throw error;

      if (data) {
        // Sort pages in a specific order
        const pageOrder = ['aktuelles', 'about', 'datenschutz', 'impressum', 'agb'];
        const sortedData = data.sort((a, b) => {
          return pageOrder.indexOf(a.page_key) - pageOrder.indexOf(b.page_key);
        });
        setPages(sortedData);
      }
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  const fetchCustomPages = async () => {
    try {
      const { data, error } = await supabase
        .from('page_content')
        .select('*')
        .eq('is_system', false);

      if (error) throw error;
      setCustomPages(data || []);
    } catch (error) {
      console.error('Error fetching custom pages:', error);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const { data, error } = await supabase
        .from('menu_items')
        .select('*')
        .order('order_position', { ascending: true });

      if (error) throw error;
      setMenuItems(data || []);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const fetchHomepageFlag = async () => {
    try {
      const { data, error } = await supabase
        .from('general_settings')
        .select('value')
        .eq('key', 'show_news_on_homepage')
        .single();

      if (error && error.code !== 'PGRST116') throw error; // PGRST116 is "not found" error
      setShowOnHomepage(data?.value ?? false);
    } catch (error) {
      console.error('Error fetching homepage flag:', error);
    }
  };

  const fetchMenuTitle = async () => {
    try {
      // Try to get the menu title
      const { data, error } = await supabase
        .from('general_settings')
        .select('*') // Select all columns to avoid 406 errors
        .eq('key', 'menu_title')
        .single();

      if (error) {
        // If the record doesn't exist, create it with a default title
        if (error.code === 'PGRST116') {
          const { error: insertError } = await supabase
            .from('general_settings')
            .insert({
              key: 'menu_title',
              text_value: 'Menü',
              description: 'Titel des Navigationsmenüs'
            });
            
          if (insertError) {
            console.error('Error creating menu title:', insertError);
          } else {
            setMenuTitle('Menü'); // Set default title after creation
          }
        } else {
          console.error('Error fetching menu title:', error);
        }
      } else if (data) {
        setMenuTitle(data.text_value || 'Menü');
      }
    } catch (error) {
      console.error('Error in fetchMenuTitle:', error);
    }
  };

  const fetchMenuSwitch = async () => {
    try {
      // Try to get the menu switch status
      const { data, error } = await supabase
        .from('general_settings')
        .select('*') // Select all columns to avoid 406 errors
        .eq('key', 'show_custom_menu')
        .single();

      if (error) {
        // If the record doesn't exist, create it with default value false
        if (error.code === 'PGRST116') {
          const { error: insertError } = await supabase
            .from('general_settings')
            .insert({
              key: 'show_custom_menu',
              value: false,
              description: 'Zeigt das benutzerdefinierte Menü an'
            });
            
          if (insertError) {
            console.error('Error creating menu switch:', insertError);
          } else {
            setShowCustomMenu(false); // Set default value after creation
          }
        } else {
          console.error('Error fetching menu switch:', error);
        }
      } else if (data) {
        setShowCustomMenu(data.value || false);
      }
    } catch (error) {
      console.error('Error in fetchMenuSwitch:', error);
    }
  };

  const toggleHomepageFlag = async () => {
    try {
      const newValue = !showOnHomepage;
      
      // First try to update if exists
      let { error } = await supabase
        .from('general_settings')
        .update({ value: newValue })
        .eq('key', 'show_news_on_homepage');

      // If no rows were updated, insert new record
      if (error?.code === 'PGRST116') {
        ({ error } = await supabase
          .from('general_settings')
          .insert({
            key: 'show_news_on_homepage',
            value: newValue,
            description: 'Zeigt den Aktuelles-Bereich auf der Startseite an'
          }));
      }

      if (error) throw error;
      setShowOnHomepage(newValue);
    } catch (error) {
      console.error('Error updating homepage flag:', error);
    }
  };

  const toggleMenuSwitch = async () => {
    try {
      const newValue = !showCustomMenu;
      
      const { error } = await supabase
        .from('general_settings')
        .update({ value: newValue })
        .eq('key', 'show_custom_menu');

      if (error) {
        console.error('Error updating menu switch:', error);
      } else {
        setShowCustomMenu(newValue);
      }
    } catch (error) {
      console.error('Error in toggleMenuSwitch:', error);
    }
  };

  const saveMenuTitle = async () => {
    try {
      setSaving(true);
      
      const { error } = await supabase
        .from('general_settings')
        .update({ text_value: menuTitle })
        .eq('key', 'menu_title');

      if (error) {
        console.error('Error saving menu title:', error);
        alert('Fehler beim Speichern des Menütitels');
      }
    } catch (error) {
      console.error('Error in saveMenuTitle:', error);
      alert('Fehler beim Speichern des Menütitels');
    } finally {
      setSaving(false);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    // Reset menu item form when changing tabs
    setMenuItemFormOpen(false);
    setEditingMenuItem(null);
    setIsAddingCustomPage(false);
    
    // Ensure we have the latest data when switching tabs
    if (newValue === 'menu') {
      fetchMenuItems();
      fetchCustomPages(); // We need custom pages for the menu form
      fetchMenuTitle();
      fetchMenuSwitch();
    } else if (newValue === 'custom') {
      fetchCustomPages();
    } else {
      fetchPages();
    }
  };

  const handleContentChange = (content: string) => {
    if (isAddingCustomPage) {
      setNewCustomPage(prev => ({ ...prev, content }));
      return;
    }

    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    setPages(prevPages =>
      prevPages.map(page =>
        page.page_key === selectedTab
          ? { ...page, content }
          : page
      )
    );
  };

  const handleCustomPageContentChange = (content: string, pageId: number) => {
    setCustomPages(prevPages =>
      prevPages.map(page =>
        page.id === pageId
          ? { ...page, content }
          : page
      )
    );
  };

  const handleTitleChange = (title: string) => {
    if (isAddingCustomPage) {
      setNewCustomPage(prev => ({ ...prev, title }));
      return;
    }

    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    setPages(prevPages =>
      prevPages.map(page =>
        page.page_key === selectedTab
          ? { ...page, title }
          : page
      )
    );
  };

  const handleCustomPageTitleChange = (title: string, pageId: number) => {
    setCustomPages(prevPages =>
      prevPages.map(page =>
        page.id === pageId
          ? { ...page, title }
          : page
      )
    );
  };

  const handleCustomPageKeyChange = (page_key: string) => {
    setNewCustomPage(prev => ({ ...prev, page_key }));
  };

  const handleSave = async () => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    setSaving(true);
    const { error } = await supabase
      .from('page_content')
      .update({ 
        content: currentPage.content,
        title: currentPage.title 
      })
      .eq('id', currentPage.id);

    if (error) {
      console.error('Error saving page:', error);
    }
    setSaving(false);
  };

  const handleSaveCustomPage = async (pageId: number) => {
    const customPage = customPages.find(p => p.id === pageId);
    if (!customPage) return;

    setSaving(true);
    const { error } = await supabase
      .from('page_content')
      .update({ 
        content: customPage.content,
        title: customPage.title 
      })
      .eq('id', customPage.id);

    if (error) {
      console.error('Error saving custom page:', error);
    } else {
      await fetchCustomPages();
    }
    setSaving(false);
  };

  const handleCreateCustomPage = async () => {
    if (!newCustomPage.title || !newCustomPage.page_key) {
      alert('Bitte füllen Sie Titel und URL aus.');
      return;
    }

    // Check if page key already exists
    const existingPage = [...pages, ...customPages].find(p => 
      p.page_key === newCustomPage.page_key
    );
    if (existingPage) {
      alert('Diese URL existiert bereits. Bitte wählen Sie eine andere URL.');
      return;
    }

    setSaving(true);
    const { error } = await supabase
      .from('page_content')
      .insert({ 
        title: newCustomPage.title,
        content: newCustomPage.content || '',
        page_key: newCustomPage.page_key,
        is_system: false
      });

    if (error) {
      console.error('Error creating custom page:', error);
      alert('Fehler beim Erstellen der Seite: ' + error.message);
    } else {
      await fetchCustomPages();
      setIsAddingCustomPage(false);
      setNewCustomPage({
        title: '',
        content: '',
        page_key: '',
        is_system: false
      });
    }
    setSaving(false);
  };

  const handleDeleteCustomPage = async (pageId: number) => {
    if (!window.confirm('Sind Sie sicher, dass Sie diese Seite löschen möchten?')) return;

    try {
      // First check if page is used in any menu items
      const { data: menuData } = await supabase
        .from('menu_items')
        .select('*')
        .eq('type', 'custom')
        .eq('value', pageId.toString());

      if (menuData && menuData.length > 0) {
        alert('Diese Seite wird in einem oder mehreren Menüpunkten verwendet. Bitte entfernen Sie zuerst diese Menüpunkte.');
        return;
      }

      const { error } = await supabase
        .from('page_content')
        .delete()
        .eq('id', pageId);

      if (error) throw error;
      await fetchCustomPages();
    } catch (error) {
      console.error('Error deleting custom page:', error);
    }
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage) return;

    const files = event.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];

    try {
      // Delete old image if exists
      if (currentPage.header_image_path) {
        await supabase.storage
          .from('page-images')
          .remove([currentPage.header_image_path]);
      }

      // Upload new image
      const fileName = `${currentPage.id}/${Date.now()}-${file.name}`;
      const { error: uploadError } = await supabase.storage
        .from('page-images')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Update page record
      const { error: updateError } = await supabase
        .from('page_content')
        .update({ header_image_path: fileName })
        .eq('id', currentPage.id);

      if (updateError) throw updateError;

      // Refresh pages
      fetchPages();
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeleteImage = async () => {
    const currentPage = pages.find(p => p.page_key === selectedTab);
    if (!currentPage?.header_image_path) return;

    try {
      // Delete from storage
      const { error: storageError } = await supabase.storage
        .from('page-images')
        .remove([currentPage.header_image_path]);

      if (storageError) throw storageError;

      // Update page record
      const { error: updateError } = await supabase
        .from('page_content')
        .update({ header_image_path: null })
        .eq('id', currentPage.id);

      if (updateError) throw updateError;

      // Refresh pages
      fetchPages();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  // Menu item handlers
  const handleAddMenuItem = () => {
    setEditingMenuItem({
      title: '',
      type: 'page',
      value: '',
      order_position: menuItems.length
    });
    setMenuItemFormOpen(true);
  };

  const handleEditMenuItem = (item: MenuItem) => {
    setEditingMenuItem(item);
    setMenuItemFormOpen(true);
  };

  const handleMenuItemChange = (field: keyof MenuItem, value: any) => {
    if (!editingMenuItem) return;
    setEditingMenuItem(prev => prev ? { ...prev, [field]: value } : null);
  };

  const handleSaveMenuItem = async () => {
    if (!editingMenuItem) return;
    setSaving(true);

    try {
      if (editingMenuItem.id) {
        // Update existing item
        const { error } = await supabase
          .from('menu_items')
          .update({
            title: editingMenuItem.title,
            type: editingMenuItem.type,
            value: editingMenuItem.value,
            order_position: editingMenuItem.order_position,
            updated_at: new Date()
          })
          .eq('id', editingMenuItem.id);

        if (error) throw error;
      } else {
        // Insert new item
        const { error } = await supabase
          .from('menu_items')
          .insert({
            title: editingMenuItem.title,
            type: editingMenuItem.type,
            value: editingMenuItem.value,
            order_position: editingMenuItem.order_position
          });

        if (error) throw error;
      }

      await fetchMenuItems();
      setMenuItemFormOpen(false);
      setEditingMenuItem(null);
    } catch (error) {
      console.error('Error saving menu item:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteMenuItem = async (id: number) => {
    if (!window.confirm('Sind Sie sicher, dass Sie diesen Menüpunkt löschen möchten?')) return;

    try {
      const { error } = await supabase
        .from('menu_items')
        .delete()
        .eq('id', id);

      if (error) throw error;
      await fetchMenuItems();
    } catch (error) {
      console.error('Error deleting menu item:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const currentPage = pages.find(p => p.page_key === selectedTab);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Einstellungen
      </Typography>

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Seiteninhalt bearbeiten
          </Typography>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ mb: 2 }}
          >
            <Tab label="Aktuelles" value="aktuelles" />
            <Tab label="Über uns" value="about" />
            <Tab label="Datenschutz" value="datenschutz" />
            <Tab label="Impressum" value="impressum" />
            <Tab label="AGB" value="agb" />
            <Tab label="Benutzerdefinierte Seiten" value="custom" />
            <Tab label="Menü" value="menu" />
          </Tabs>

          {selectedTab === 'custom' ? (
            // Custom Pages Tab
            <Box>
              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />} 
                onClick={() => setIsAddingCustomPage(true)}
                sx={{ mb: 2 }}
              >
                Eigene Seite erstellen
              </Button>

              {isAddingCustomPage && (
                <Card sx={{ mb: 3, p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Neue Seite erstellen
                  </Typography>
                  
                  <TextField
                    fullWidth
                    label="Seitentitel"
                    value={newCustomPage.title}
                    onChange={(e) => handleTitleChange(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  
                  <TextField
                    fullWidth
                    label="URL-Pfad"
                    value={newCustomPage.page_key}
                    onChange={(e) => handleCustomPageKeyChange(e.target.value)}
                    sx={{ mb: 2 }}
                    helperText="Wird in der URL verwendet, z.B. 'team' für /team"
                  />
                  
                  <Box sx={{ mb: 2, '& .ql-editor': { minHeight: '300px' } }}>
                    <ReactQuill
                      value={newCustomPage.content || ''}
                      onChange={handleContentChange}
                      modules={quillModules}
                      formats={quillFormats}
                    />
                  </Box>
                  
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="contained"
                      onClick={handleCreateCustomPage}
                      disabled={saving}
                    >
                      {saving ? 'Speichern...' : 'Seite erstellen'}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setIsAddingCustomPage(false)}
                    >
                      Abbrechen
                    </Button>
                  </Box>
                </Card>
              )}

              {customPages.length > 0 ? (
                customPages.map(page => (
                  <Card key={page.id} sx={{ mb: 3, p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6">
                        {page.title}
                      </Typography>
                      <IconButton onClick={() => handleDeleteCustomPage(page.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    
                    <Typography variant="caption" sx={{ mb: 2, display: 'block' }}>
                      URL: /{page.page_key}
                    </Typography>
                    
                    <TextField
                      fullWidth
                      label="Seitentitel"
                      value={page.title}
                      onChange={(e) => handleCustomPageTitleChange(e.target.value, page.id)}
                      sx={{ mb: 2 }}
                    />
                    
                    <Box sx={{ mb: 2, '& .ql-editor': { minHeight: '300px' } }}>
                      <ReactQuill
                        value={page.content}
                        onChange={(content) => handleCustomPageContentChange(content, page.id)}
                        modules={quillModules}
                        formats={quillFormats}
                      />
                    </Box>
                    
                    <Button
                      variant="contained"
                      onClick={() => handleSaveCustomPage(page.id)}
                      disabled={saving}
                    >
                      {saving ? 'Speichern...' : 'Speichern'}
                    </Button>
                  </Card>
                ))
              ) : (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Keine benutzerdefinierten Seiten vorhanden. Erstellen Sie Ihre erste eigene Seite!
                </Typography>
              )}
            </Box>
          ) : selectedTab === 'menu' ? (
            // Menu Items Tab
            <Box>
              <Card sx={{ mb: 3, p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Menü Einstellungen
                </Typography>
                
                <FormControlLabel
                  control={
                    <Switch
                      checked={showCustomMenu}
                      onChange={toggleMenuSwitch}
                    />
                  }
                  label="Benutzerdefiniertes Menü anzeigen"
                  sx={{ mb: 2, display: 'block' }}
                />
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TextField
                    label="Menü Titel"
                    value={menuTitle}
                    onChange={(e) => setMenuTitle(e.target.value)}
                    sx={{ mr: 2, flexGrow: 1 }}
                    helperText="Dieser Text wird als Titel des Menüs angezeigt"
                    disabled={!showCustomMenu}
                  />
                  <Button
                    variant="contained"
                    onClick={saveMenuTitle}
                    disabled={saving || !showCustomMenu}
                  >
                    {saving ? 'Speichern...' : 'Speichern'}
                  </Button>
                </Box>
              </Card>

              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<AddIcon />} 
                onClick={handleAddMenuItem}
                sx={{ mb: 2 }}
              >
                Menüpunkt hinzufügen
              </Button>

              {menuItemFormOpen && editingMenuItem && (
                <Card sx={{ mb: 3, p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    {editingMenuItem.id ? 'Menüpunkt bearbeiten' : 'Neuer Menüpunkt'}
                  </Typography>
                  
                  <TextField
                    fullWidth
                    label="Titel"
                    value={editingMenuItem.title}
                    onChange={(e) => handleMenuItemChange('title', e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Typ</InputLabel>
                    <Select
                      value={editingMenuItem.type}
                      label="Typ"
                      onChange={(e) => handleMenuItemChange('type', e.target.value)}
                    >
                      <MuiMenuItem value="page">Systemseite</MuiMenuItem>
                      <MuiMenuItem value="custom">Benutzerdefinierte Seite</MuiMenuItem>
                      <MuiMenuItem value="url">Externe URL</MuiMenuItem>
                    </Select>
                  </FormControl>
                  
                  {editingMenuItem.type === 'page' ? (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>Seite</InputLabel>
                      <Select
                        value={editingMenuItem.value}
                        label="Seite"
                        onChange={(e) => handleMenuItemChange('value', e.target.value)}
                      >
                        {pages.map(page => (
                          <MuiMenuItem key={page.page_key} value={page.page_key}>
                            {page.title}
                          </MuiMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : editingMenuItem.type === 'custom' ? (
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>Benutzerdefinierte Seite</InputLabel>
                      <Select
                        value={editingMenuItem.value}
                        label="Benutzerdefinierte Seite"
                        onChange={(e) => handleMenuItemChange('value', e.target.value)}
                      >
                        {customPages.map(page => (
                          <MuiMenuItem key={page.id} value={page.id.toString()}>
                            {page.title}
                          </MuiMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      label="URL"
                      value={editingMenuItem.value}
                      onChange={(e) => handleMenuItemChange('value', e.target.value)}
                      sx={{ mb: 2 }}
                      placeholder="https://example.com"
                    />
                  )}
                  
                  <TextField
                    fullWidth
                    type="number"
                    label="Reihenfolge"
                    value={editingMenuItem.order_position}
                    onChange={(e) => handleMenuItemChange('order_position', parseInt(e.target.value))}
                    sx={{ mb: 2 }}
                  />
                  
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                      variant="contained"
                      onClick={handleSaveMenuItem}
                      disabled={saving}
                    >
                      {saving ? 'Speichern...' : 'Speichern'}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setMenuItemFormOpen(false)}
                    >
                      Abbrechen
                    </Button>
                  </Box>
                </Card>
              )}

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Reihenfolge</TableCell>
                      <TableCell>Titel</TableCell>
                      <TableCell>Typ</TableCell>
                      <TableCell>Ziel</TableCell>
                      <TableCell>Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {menuItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.order_position}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>
                          {item.type === 'page' 
                            ? 'Systemseite' 
                            : item.type === 'custom'
                            ? 'Benutzerdefinierte Seite'
                            : 'Externe URL'}
                        </TableCell>
                        <TableCell>
                          {item.type === 'page' 
                            ? pages.find(p => p.page_key === item.value)?.title || item.value 
                            : item.type === 'custom'
                            ? customPages.find(p => p.id.toString() === item.value)?.title || item.value
                            : item.value}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEditMenuItem(item)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => item.id && handleDeleteMenuItem(item.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : currentPage ? (
            // Regular Page Content Edit
            <>
              <Box sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  label="Seitentitel"
                  variant="outlined"
                  value={currentPage.title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  sx={{ mb: 2 }}
                />
                {selectedTab === 'aktuelles' && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOnHomepage}
                        onChange={toggleHomepageFlag}
                      />
                    }
                    label="Hinweis auf Startseite anzeigen"
                  />
                )}
              </Box>

              {PAGES_WITH_HEADER.includes(selectedTab) && (
                <Box sx={{ mb: 3 }}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    Header Bild {headerImageUrl ? 'ändern' : 'hochladen'}
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={handleImageUpload}
                    />
                  </Button>

                  {headerImageUrl && (
                    <Box sx={{ position: 'relative', mt: 2, maxWidth: 600 }}>
                      <img
                        src={headerImageUrl}
                        alt="Header"
                        style={{ width: '100%', height: 'auto' }}
                      />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: 'white',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                          },
                        }}
                        onClick={handleDeleteImage}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}

              <Box sx={{ mb: 2, '& .ql-editor': { minHeight: '300px' } }}>
                <ReactQuill
                  value={currentPage.content}
                  onChange={handleContentChange}
                  modules={quillModules}
                  formats={quillFormats}
                />
              </Box>

              <Button
                variant="contained"
                onClick={handleSave}
                disabled={saving}
              >
                {saving ? 'Speichern...' : 'Speichern'}
              </Button>
            </>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Settings; 