import React from 'react';
import { List, Datagrid, TextField, EmailField, TextInput } from 'react-admin';
import DuplikatDetector from './DuplikatDetector';

const kundeFilters = [
    <TextInput 
        label="Suche Einrichtung" 
        source="einrichtung_like" 
        alwaysOn
        placeholder="Suche nach Einrichtung"
    />,
    <TextInput 
        label="Suche Ansprechpartner" 
        source="ansprechpartner_like" 
        alwaysOn
        placeholder="Suche nach Ansprechpartner"
    />,
];

export const KundeList = (props: any) => (
    <>
        <DuplikatDetector />
        <List {...props} filters={kundeFilters}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="einrichtung" />
                <TextField source="adresse" />
                <TextField source="plz" />
                <TextField source="ort" />
                <TextField source="ansprechpartner" />
                <TextField source="telefon" />
                <EmailField source="email" />
            </Datagrid>
        </List>
    </>
);

export default KundeList;