import React from 'react';
import { Edit, SimpleForm, TextInput, email, SelectInput, FormDataConsumer, Button } from 'react-admin';

export const KundeEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="einrichtung" />
            <TextInput source="adresse" multiline />
            <TextInput source="plz" />
            <TextInput source="ort" />
            <SelectInput 
                source="anrede" 
                choices={[
                    { id: 'Herr', name: 'Herr' },
                    { id: 'Frau', name: 'Frau' },
                    { id: 'Divers', name: 'Divers' },
                ]}
            />
            <TextInput source="ansprechpartner" />
            <TextInput source="telefon" />
            <TextInput source="email" validate={[email()]} />
            
            {/* Email compose button */}
            <FormDataConsumer>
                {({ formData }) => formData.email ? (
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            label="Email verfassen"
                            onClick={() => window.open(`mailto:${formData.email}`, '_blank')}
                            color="primary"
                            variant="contained"
                        />
                    </div>
                ) : null}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export default KundeEdit;