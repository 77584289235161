import React, { useState } from 'react';
import { 
    useDataProvider, 
    Button,
    SimpleForm,
    useNotify,
    useRefresh
} from 'react-admin';
import { 
    Paper, 
    Typography, 
    List, 
    ListItem, 
    ListItemText, 
    Divider, 
    Grid,
    CircularProgress,
    Collapse,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    FormControlLabel,
    Box,
    Tooltip,
    Card,
    CardContent
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import MergeIcon from '@mui/icons-material/Merge';

// Interface für Kundendaten
interface Kunde {
    id: number;
    einrichtung: string;
    ansprechpartner: string;
    adresse?: string;
    plz?: string;
    ort?: string;
    anrede?: string;
    telefon?: string;
    email?: string;
}

// Interface für potentielle Duplikate
interface PotentialDuplicate {
    original: Kunde;
    duplicates: Kunde[];
    selectedDuplicates: number[]; // IDs der ausgewählten Duplikate
}

const DuplikatDetector: React.FC = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    
    const [loading, setLoading] = useState(false);
    const [potentialDuplicates, setPotentialDuplicates] = useState<PotentialDuplicate[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<PotentialDuplicate | null>(null);
    const [openMergeDialog, setOpenMergeDialog] = useState(false);
    const [expandedGroups, setExpandedGroups] = useState<{[key: number]: boolean}>({});
    const [mergingInProgress, setMergingInProgress] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [hasSearched, setHasSearched] = useState(false);
    
    // Funktionen zur Handhabung von Checkbox-Auswahlen
    const toggleDuplicateSelection = (groupId: number, duplicateId: number) => {
        setPotentialDuplicates(prevGroups => 
            prevGroups.map(group => 
                group.original.id === groupId 
                    ? {
                        ...group,
                        selectedDuplicates: group.selectedDuplicates.includes(duplicateId)
                            ? group.selectedDuplicates.filter(id => id !== duplicateId)
                            : [...group.selectedDuplicates, duplicateId]
                    }
                    : group
            )
        );
    };

    // Alle Duplikate in einer Gruppe auswählen/abwählen
    const toggleAllDuplicatesInGroup = (groupId: number, select: boolean) => {
        setPotentialDuplicates(prevGroups => 
            prevGroups.map(group => 
                group.original.id === groupId 
                    ? {
                        ...group,
                        selectedDuplicates: select 
                            ? group.duplicates.map(d => d.id) 
                            : []
                    }
                    : group
            )
        );
    };
    
    // Berechnet, wie vollständig ein Kundendatensatz ist
    const calculateCompleteness = (kunde: Kunde): number => {
        let score = 0;
        const fields = ['einrichtung', 'ansprechpartner', 'adresse', 'plz', 'ort', 'anrede', 'telefon', 'email'];
        
        for (const field of fields) {
            const value = kunde[field as keyof Kunde];
            if (value && typeof value === 'string' && value.trim() !== '') {
                score += 1;
            }
        }
        
        return score;
    };
    
    // Findet den vollständigsten Kundendatensatz in einer Gruppe
    const findMostCompleteRecord = (customers: Kunde[]): Kunde => {
        if (customers.length === 0) return {} as Kunde;
        if (customers.length === 1) return customers[0];
        
        return customers.reduce((mostComplete, current) => {
            const currentScore = calculateCompleteness(current);
            const mostCompleteScore = calculateCompleteness(mostComplete);
            
            return currentScore > mostCompleteScore ? current : mostComplete;
        }, customers[0]);
    };
    
    // Funktion zum Finden potenzieller Duplikate
    const findDuplicates = async () => {
        setLoading(true);
        setHasSearched(true);
        
        try {
            // Alle Kunden abrufen
            const { data: kunden } = await dataProvider.getList('kunde', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'ASC' },
                filter: {}
            });
            
            // Gruppierung nach ähnlichen Einrichtungsnamen und Ansprechpartnern
            const potentialDuplicateGroups: PotentialDuplicate[] = [];
            
            // Hilfsfunktion zur Ähnlichkeitsprüfung
            const isSimilar = (str1: string = '', str2: string = '') => {
                if (!str1 || !str2) return false;
                str1 = str1.toLowerCase().trim();
                str2 = str2.toLowerCase().trim();
                
                // Exakte Übereinstimmung
                if (str1 === str2) return true;
                
                // Einfacher Ähnlichkeitsvergleich: enthält eine Teilzeichenfolge
                if (str1.includes(str2) || str2.includes(str1)) return true;
                
                // Levenshtein-Distanz für ähnliche Namen könnte hier implementiert werden
                
                return false;
            };
            
            // Duplikate finden
            for (let i = 0; i < kunden.length; i++) {
                const original = kunden[i];
                const duplicates: Kunde[] = [];
                
                for (let j = 0; j < kunden.length; j++) {
                    if (i === j) continue; // Nicht den gleichen Eintrag vergleichen
                    
                    const potential = kunden[j];
                    
                    // Prüfe Ähnlichkeit bei BEIDEN: Einrichtung UND Ansprechpartner
                    if (
                        isSimilar(original.einrichtung, potential.einrichtung) && 
                        isSimilar(original.ansprechpartner, potential.ansprechpartner)
                    ) {
                        duplicates.push(potential);
                    }
                }
                
                // Nur hinzufügen, wenn Duplikate gefunden wurden
                if (duplicates.length > 0) {
                    potentialDuplicateGroups.push({
                        original,
                        duplicates,
                        selectedDuplicates: []
                    });
                }
            }
            
            // Duplikate nur einmal anzeigen (entfernt redundante Gruppen)
            const uniqueGroups = potentialDuplicateGroups.filter((group, index) => {
                for (let i = 0; i < index; i++) {
                    const otherGroup = potentialDuplicateGroups[i];
                    if (otherGroup.duplicates.some(d => d.id === group.original.id)) {
                        return false;
                    }
                }
                return true;
            });
            
            setPotentialDuplicates(uniqueGroups);
            
            if (uniqueGroups.length === 0) {
                notify('Keine Duplikate gefunden', { type: 'info' });
            } else {
                notify(`${uniqueGroups.length} Duplikatgruppen gefunden`, { type: 'success' });
            }
        } catch (error) {
            console.error('Fehler beim Suchen von Duplikaten:', error);
            notify('Fehler beim Suchen von Duplikaten', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };
    
    // Funktion zum Umschalten des erweiterten Zustands einer Gruppe
    const toggleGroupExpansion = (id: number) => {
        setExpandedGroups(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };
    
    // Merge-Dialog öffnen
    const openMerge = (group: PotentialDuplicate) => {
        if (group.selectedDuplicates.length === 0) {
            notify('Bitte wählen Sie mindestens ein Duplikat aus', { type: 'warning' });
            return;
        }
        
        setSelectedGroup(group);
        setOpenMergeDialog(true);
    };
    
    // Kunden zusammenführen
    const mergeCustomers = async () => {
        if (!selectedGroup) return;
        
        setMergingInProgress(true);
        
        try {
            // Alle relevanten Kunden sammeln
            const allCustomers = [
                selectedGroup.original,
                ...selectedGroup.duplicates.filter(d => selectedGroup.selectedDuplicates.includes(d.id))
            ];
            
            // Den vollständigsten Kundendatensatz finden
            const keepCustomer = findMostCompleteRecord(allCustomers);
            
            // Alle anderen Kunden (außer dem beizubehaltenden) löschen
            const customersToDelete = allCustomers.filter(c => c.id !== keepCustomer.id);
            
            // Alle Bestellungen der zu löschenden Kunden auf den beizubehaltenden übertragen
            for (const deleteCustomer of customersToDelete) {
                // Bestellungen finden
                const { data: bestellungen } = await dataProvider.getList('bestellung', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'id', order: 'ASC' },
                    filter: { kunde_id: deleteCustomer.id }
                });
                
                // Bestellungen aktualisieren
                for (const bestellung of bestellungen) {
                    await dataProvider.update('bestellung', {
                        id: bestellung.id,
                        data: { 
                            kunde_id: keepCustomer.id,
                            // Nur die notwendige Änderung des kunde_id Felds, 
                            // keine anderen Felder, um Konflikte zu vermeiden
                        },
                        previousData: bestellung
                    });
                }
                
                // Kunden löschen
                await dataProvider.delete('kunde', { id: deleteCustomer.id });
            }
            
            notify(`${customersToDelete.length} Kunden erfolgreich zusammengeführt`, { type: 'success' });
            setOpenMergeDialog(false);
            setSelectedGroup(null);
            
            // Liste der potenziellen Duplikate aktualisieren
            findDuplicates();
            refresh();
        } catch (error) {
            console.error('Fehler beim Zusammenführen:', error);
            notify('Fehler beim Zusammenführen der Kunden', { type: 'error' });
        } finally {
            setMergingInProgress(false);
        }
    };
    
    // Zeigt die Details der zu zusammenführenden Kunden an
    const renderMergeDetails = () => {
        if (!selectedGroup) return null;
        
        const allCustomers = [
            selectedGroup.original,
            ...selectedGroup.duplicates.filter(d => selectedGroup.selectedDuplicates.includes(d.id))
        ];
        
        const bestCustomer = findMostCompleteRecord(allCustomers);
        
        return (
            <>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Der vollständigste Datensatz wird als Basis verwendet:
                </Typography>
                
                <Paper elevation={3} sx={{ p: 2, mb: 3, bgcolor: '#f0f7ff' }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                        Basis-Datensatz (ID: {bestCustomer.id})
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography>Einrichtung: {bestCustomer.einrichtung || '-'}</Typography>
                            <Typography>Ansprechpartner: {bestCustomer.ansprechpartner || '-'}</Typography>
                            <Typography>Adresse: {bestCustomer.adresse || '-'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>PLZ/Ort: {bestCustomer.plz || '-'} {bestCustomer.ort || '-'}</Typography>
                            <Typography>Email: {bestCustomer.email || '-'}</Typography>
                            <Typography>Telefon: {bestCustomer.telefon || '-'}</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                        Score: {calculateCompleteness(bestCustomer)}/8 Felder ausgefüllt
                    </Typography>
                </Paper>
                
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Folgende Datensätze werden gelöscht:
                </Typography>
                
                <Grid container spacing={2}>
                    {allCustomers
                        .filter(customer => customer.id !== bestCustomer.id)
                        .map(customer => (
                            <Grid item xs={12} md={6} key={customer.id}>
                                <Paper elevation={2} sx={{ p: 2 }}>
                                    <Typography variant="subtitle2">
                                        ID: {customer.id}
                                    </Typography>
                                    <Typography>Einrichtung: {customer.einrichtung || '-'}</Typography>
                                    <Typography>Ansprechpartner: {customer.ansprechpartner || '-'}</Typography>
                                    <Typography>Email: {customer.email || '-'}</Typography>
                                    <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                                        Score: {calculateCompleteness(customer)}/8 Felder ausgefüllt
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                </Grid>
                
                <Typography variant="body2" color="error" sx={{ mt: 3 }}>
                    Achtung: Die ausgewählten Kunden werden gelöscht und alle ihre Bestellungen werden dem Basis-Datensatz zugeordnet.
                    Dieser Vorgang kann nicht rückgängig gemacht werden.
                </Typography>
            </>
        );
    };
    
    // Toggle für das Auf-/Zuklappen der gesamten Komponente
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    
    return (
        <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <WarningIcon color="warning" />
                </Grid>
                <Grid item xs>
                    <Typography variant="h6">Duplikat-Erkennung</Typography>
                </Grid>
                <Grid item>
                    {!loading && (
                        <IconButton onClick={toggleExpansion} size="small">
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            
            <Collapse in={isExpanded}>
                <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        label="Duplikate suchen"
                        onClick={findDuplicates}
                        disabled={loading}
                        startIcon={<SearchIcon />}
                    />
                </Box>
                
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                        <CircularProgress />
                    </div>
                )}
                
                {!loading && hasSearched && potentialDuplicates.length === 0 && (
                    <Typography variant="body2" style={{ padding: '10px 0', textAlign: 'center', color: '#666' }}>
                        Keine Duplikate gefunden.
                    </Typography>
                )}
                
                {!loading && potentialDuplicates.length > 0 && (
                    <List>
                        {potentialDuplicates.map((group) => (
                            <React.Fragment key={group.original.id}>
                                <ListItem>
                                    <Grid container alignItems="center">
                                        <Grid item xs>
                                            <ListItemText
                                                primary={`${group.original.einrichtung || 'Keine Einrichtung'}`}
                                                secondary={`${group.original.ansprechpartner || 'Kein Ansprechpartner'} | ${group.duplicates.length} potenzielle Duplikate`}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Alle auswählen">
                                                <IconButton onClick={() => toggleAllDuplicatesInGroup(group.original.id, true)} size="small">
                                                    <Checkbox 
                                                        checked={group.selectedDuplicates.length === group.duplicates.length && group.duplicates.length > 0}
                                                        indeterminate={group.selectedDuplicates.length > 0 && group.selectedDuplicates.length < group.duplicates.length}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Zusammenführen">
                                                <span>
                                                    <IconButton 
                                                        color="primary"
                                                        onClick={() => openMerge(group)}
                                                        disabled={group.selectedDuplicates.length === 0}
                                                        size="small"
                                                    >
                                                        <MergeIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <IconButton onClick={() => toggleGroupExpansion(group.original.id)} size="small">
                                                {expandedGroups[group.original.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                
                                <Collapse in={expandedGroups[group.original.id] || false}>
                                    <Box sx={{ pl: 4, pr: 2 }}>
                                        <Card variant="outlined" sx={{ mb: 2 }}>
                                            <CardContent>
                                                <Typography variant="subtitle2">Original (Basis zum Vergleich):</Typography>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="body2">ID: {group.original.id}</Typography>
                                                        <Typography variant="body2">Einrichtung: {group.original.einrichtung || '-'}</Typography>
                                                        <Typography variant="body2">Ansprechpartner: {group.original.ansprechpartner || '-'}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography variant="body2">Email: {group.original.email || '-'}</Typography>
                                                        <Typography variant="body2">Telefon: {group.original.telefon || '-'}</Typography>
                                                        <Typography variant="body2">Adresse: {group.original.adresse || '-'}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                        
                                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Potenzielle Duplikate:</Typography>
                                        <List disablePadding>
                                            {group.duplicates.map((duplicate) => (
                                                <ListItem key={duplicate.id} sx={{ border: '1px solid #eee', mb: 1, borderRadius: 1 }}>
                                                    <Grid container alignItems="center">
                                                        <Grid item>
                                                            <Checkbox 
                                                                checked={group.selectedDuplicates.includes(duplicate.id)}
                                                                onChange={() => toggleDuplicateSelection(group.original.id, duplicate.id)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <ListItemText
                                                                primary={`${duplicate.einrichtung || 'Keine Einrichtung'}`}
                                                                secondary={
                                                                    <>
                                                                        <Typography variant="body2">
                                                                            Ansprechpartner: {duplicate.ansprechpartner || '-'} | ID: {duplicate.id}
                                                                        </Typography>
                                                                        <Typography variant="body2">
                                                                            Email: {duplicate.email || '-'} | Tel: {duplicate.telefon || '-'}
                                                                        </Typography>
                                                                    </>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Tooltip title="Score">
                                                                <Box sx={{ 
                                                                    display: 'flex', 
                                                                    alignItems: 'center', 
                                                                    bgcolor: '#f5f5f5', 
                                                                    borderRadius: 1, 
                                                                    p: 0.5 
                                                                }}>
                                                                    <InfoIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                                                                    <Typography variant="caption">
                                                                        {calculateCompleteness(duplicate)}/8
                                                                    </Typography>
                                                                </Box>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>
                                </Collapse>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                )}
            </Collapse>
            
            {/* Merge-Dialog */}
            <Dialog 
                open={openMergeDialog} 
                onClose={() => !mergingInProgress && setOpenMergeDialog(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    Kunden zusammenführen
                    {selectedGroup && (
                        <Typography variant="caption" display="block">
                            {selectedGroup.selectedDuplicates.length} Datensätze werden zu einem zusammengeführt
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent>
                    {mergingInProgress ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', p: 4 }}>
                            <CircularProgress sx={{ mb: 2 }} />
                            <Typography>Kunden werden zusammengeführt...</Typography>
                        </Box>
                    ) : (
                        renderMergeDetails()
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        label="Abbrechen" 
                        onClick={() => setOpenMergeDialog(false)}
                        disabled={mergingInProgress}
                    />
                    <Button
                        label="Zusammenführen"
                        onClick={mergeCustomers}
                        disabled={mergingInProgress}
                        color="primary"
                    />
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default DuplikatDetector; 