import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { Box, Typography, Grid } from '@mui/material';
import YearSelector from './widgets/YearSelector';
import MuseumSelector from './widgets/MuseumSelector';
import MuseumStatsChart from './charts/MuseumStatsChart';
import TopProductsChart from './charts/TopProductsChart';
import TopMitarbeiterChart from './charts/TopMitarbeiterChart';
import MitarbeiterGrid from './widgets/MitarbeiterSelector';
import { FilterState, Stats, ProduktStat, MitarbeiterStat, BezirkStat } from './types';
import StatusPieChart from './charts/StatusPieChart';
import BezirkPieChart from './charts/BezirkPieChart';
import StatsHighlight from './widgets/StatsHighlight';

const AusgabenBreakdown: React.FC<{
    stundenlohn: number;
    fahrtkosten: number;
}> = ({ stundenlohn, fahrtkosten }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Box>
            <Box 
                onClick={() => setExpanded(!expanded)} 
                sx={{ 
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1
                }}
            >
                <Typography>
                    {(stundenlohn + fahrtkosten).toLocaleString('de-DE')}€
                </Typography>
                <Typography 
                    sx={{ 
                        ml: 1,
                        transform: expanded ? 'rotate(180deg)' : 'none',
                        transition: 'transform 0.3s'
                    }}
                >
                    ▼
                </Typography>
            </Box>
            {expanded && (
                <Box sx={{ pl: 2 }}>
                    <Typography>Stundenlohn: {stundenlohn.toLocaleString('de-DE')}€</Typography>
                    <Typography>Fahrtkosten: {fahrtkosten.toLocaleString('de-DE')}€</Typography>
                </Box>
            )}
        </Box>
    );
};

const AnalyticsDashboard: React.FC = () => {
    const dataProvider = useDataProvider();
    const [initialized, setInitialized] = useState(false);
    const [filters, setFilters] = useState<FilterState>({
        year: new Date().getFullYear(),
        month: 'all',
        museum_id: 'all',
        product_id: 'all',
        mitarbeiter_id: 'all'
    });
    
    const [museums, setMuseums] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [mitarbeiter, setMitarbeiter] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState<Stats>({ 
        museumStats: [], 
        produktStats: [], 
        mitarbeiterStats: [], 
        statusStats: [], 
        produktStatsAbgeschlossen: [],
        bezirkStats: [],
        yearlyTotals: {
            bestellungen: 0,
            stundenlohn: 0,
            fahrtkosten: 0,
            ausgaben: 0
        }
    });
    const [availableYears, setAvailableYears] = useState<number[]>([]);
    const [selectedMuseums, setSelectedMuseums] = useState<number[]>([]);
    const [selectedMitarbeiterId, setSelectedMitarbeiterId] = useState<number | null>(null);

    // Load reference data and initialize years
    useEffect(() => {
        const initialize = async () => {
            try {
                setLoading(true);
                // Load museums
                const { data: museumsData } = await dataProvider.getList('museum', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {}
                });
                setMuseums(museumsData);
                setSelectedMuseums(museumsData.map(m => m.id));

                // Load products
                const { data: productsData } = await dataProvider.getList('produkt', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {}
                });
                setProducts(productsData);

                // Load mitarbeiter
                const { data: mitarbeiterData } = await dataProvider.getList('mitarbeiter', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'nachname', order: 'ASC' },
                    filter: {}
                });
                setMitarbeiter(mitarbeiterData);

                // Load and set available years
                const { data: terminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'vorschlagdatetime', order: 'DESC' },
                    filter: {}
                });

                const years = [...new Set(terminvorschlaege.map(tv => 
                    new Date(tv.vorschlagdatetime).getFullYear()
                ))].sort((a, b) => b - a);

                setAvailableYears(years);
                
                const currentYear = new Date().getFullYear();
                const initialYear = years.includes(currentYear) ? currentYear : years[0];
                
                setFilters(prev => ({
                    ...prev,
                    year: initialYear
                }));

                setInitialized(true);
            } catch (error) {
                console.error('Error initializing dashboard:', error);
            } finally {
                setLoading(false);
            }
        };

        initialize();
    }, [dataProvider]);

    // Fetch termine data for charts
    useEffect(() => {
        if (!initialized) return;
        
        const fetchData = async () => {
            setLoading(true);
            try {
                // Get all bestellungen first
                const { data: alleBestellungen } = await dataProvider.getList('bestellung', {
                    pagination: { page: 1, perPage: 1000 },
                    filter: {}
                });

                // Get all terminvorschläge for the selected year
                const { data: alleTerminvorschlaege } = await dataProvider.getList('terminvorschlag', {
                    pagination: { page: 1, perPage: 1000 },
                    filter: {}
                });

                // Filter terminvorschläge for selected year and get unique bestellung_ids
                const bestellungIdsForYear = [...new Set(alleTerminvorschlaege
                    .filter(tv => new Date(tv.vorschlagdatetime).getFullYear() === filters.year)
                    .map(tv => tv.bestellung_id)
                )];

                // Filter bestellungen that have terminvorschläge in the selected year
                const thisYearBestellungen = alleBestellungen.filter(b => 
                    bestellungIdsForYear.includes(b.id)
                );

                console.log('Ausgewähltes Jahr:', filters.year);
                console.log('Gefilterte Bestellungen:', thisYearBestellungen);
                console.log('Davon abgeschlossen:', thisYearBestellungen.filter(b => b.status === 'abgeschlossen').length);
                console.log('Davon storniert:', thisYearBestellungen.filter(b => b.status === 'storniert').length);
                console.log('Andere Status:', thisYearBestellungen.filter(b => b.status !== 'abgeschlossen' && b.status !== 'storniert').length);

                const statusStats = [
                    {
                        name: 'Abgeschlossen',
                        value: thisYearBestellungen.filter(b => b.status === 'abgeschlossen').length || 0
                    },
                    {
                        name: 'Storniert',
                        value: thisYearBestellungen.filter(b => b.status === 'storniert').length || 0
                    }
                ];

                console.log('Status Stats:', statusStats);

                // Get termine for all accepted termine from abgeschlossene bestellungen
                const { data: termine } = await dataProvider.getList('terminvorschlag', {
                    pagination: { page: 1, perPage: 1000 },
                    filter: {
                        status: 'akzeptiert'
                    },
                    sort: { field: 'vorschlagdatetime', order: 'ASC' }
                });

                // Get related bestellungen
                const bestellungIds = [...new Set(termine.map(t => t.bestellung_id))];
                const { data: bestellungen } = await dataProvider.getMany('bestellung', {
                    ids: bestellungIds
                });

                // Filter for abgeschlossene bestellungen
                const abgeschlosseneBestellungen = bestellungen.filter(b => b.status === 'abgeschlossen');
                const abgeschlosseneBestellungIds = abgeschlosseneBestellungen.map(b => b.id);

                // Filter termine for abgeschlossene bestellungen
                const abgeschlosseneTermine = termine.filter(t => 
                    abgeschlosseneBestellungIds.includes(t.bestellung_id)
                );

                // Get related products
                const produktIds = [...new Set(bestellungen.map(b => b.produkt_id))];
                const { data: produkte } = await dataProvider.getMany('produkt', {
                    ids: produktIds
                });

                // Calculate museum stats
                const museumStats = await Promise.all(museums
                    .filter(museum => selectedMuseums.includes(museum.id))
                    .map(async museum => {
                        const museumProdukte = produkte.filter(p => p.museum_id === museum.id);
                        // Filter nur abgeschlossene Bestellungen
                        const museumBestellungen = abgeschlosseneBestellungen.filter(b => 
                            museumProdukte.some(p => p.id === b.produkt_id)
                        );
                        // Nur Termine von abgeschlossenen Bestellungen
                        const museumTermine = abgeschlosseneTermine.filter(t => 
                            museumBestellungen.some(b => b.id === t.bestellung_id) &&
                            new Date(t.vorschlagdatetime).getFullYear() === filters.year
                        );

                        // Einnahmen berechnen - nur für abgeschlossene Bestellungen
                        const einnahmen = museumTermine.reduce((sum, termin) => {
                            const bestellung = museumBestellungen.find(b => b.id === termin.bestellung_id);
                            const produkt = produkte.find(p => p.id === bestellung?.produkt_id);
                            return sum + (produkt?.kosten || 0) * (bestellung?.anzahltn || 1);
                        }, 0);

                        // Ausgaben berechnen - nur für abgeschlossene Bestellungen
                        const ausgabenDetails = await museumTermine.reduce(async (sumPromise, termin) => {
                            const sum = await sumPromise;
                            const bestellung = museumBestellungen.find(b => b.id === termin.bestellung_id);
                            const produkt = produkte.find(p => p.id === bestellung?.produkt_id);
                            
                            // Get mitarbeiter IDs from relmitarbeiterstatus
                            const { data: mitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
                                pagination: { page: 1, perPage: 1000 },
                                filter: {
                                    terminvorschlag_id: termin.id,
                                    angenommen: true
                                }
                            });
                            
                            const mitarbeiterIds = mitarbeiterStatus.map(status => status.mitarbeiter_id);
                            console.log('Mitarbeiter IDs for termin:', termin.id, mitarbeiterIds);
                            
                            const mitarbeiterDetails = await Promise.all(
                                mitarbeiterIds.map((id: number) => 
                                    dataProvider.getOne('mitarbeiter', { id })
                                        .then(response => {
                                            console.log('Mitarbeiter details for ID:', id, response.data);
                                            return response.data;
                                        })
                                        .catch(() => null)
                                )
                            );
                            
                            const fahrtkosten = mitarbeiterDetails
                                .filter(m => m !== null)
                                .reduce((sum, m) => {
                                    console.log('Adding fahrtkosten for mitarbeiter:', m.id, m.fahrtkosten);
                                    return sum + (m.fahrtkosten || 0);
                                }, 0);
                            
                            console.log('Total fahrtkosten for termin:', termin.id, fahrtkosten);
                            
                            // Only calculate costs if we have mitarbeiter and a valid duration
                            const anzahlMitarbeiter = mitarbeiterIds.length;
                            if (anzahlMitarbeiter === 0) {
                                console.log('No mitarbeiter found for termin:', termin.id);
                                return {
                                    stundenlohn: sum.stundenlohn,
                                    fahrtkosten: sum.fahrtkosten
                                };
                            }

                            const dauer = produkt?.dauer || 0;
                            console.log('Product details:', {
                                id: produkt?.id,
                                name: produkt?.name,
                                raw_dauer: produkt?.dauer,
                                parsed_dauer: dauer,
                                type_of_dauer: typeof produkt?.dauer
                            });

                            if (dauer === 0) {
                                console.log('No duration found for produkt:', produkt?.id);
                                return {
                                    stundenlohn: sum.stundenlohn,
                                    fahrtkosten: sum.fahrtkosten
                                };
                            }

                            // Convert duration to hours if it's in minutes
                            const dauerInHours = dauer;
                            console.log('Termin:', termin.id, {
                                produkt_name: produkt?.name,
                                original_dauer: dauer,
                                dauer_in_hours: dauerInHours,
                                anzahlMitarbeiter,
                                stundenlohn_calc: `50 * ${dauerInHours} * ${anzahlMitarbeiter}`
                            });
                            const stundenlohn = 50 * dauerInHours * anzahlMitarbeiter;
                            console.log('Calculated stundenlohn:', stundenlohn);
                            
                            return {
                                stundenlohn: sum.stundenlohn + stundenlohn,
                                fahrtkosten: sum.fahrtkosten + fahrtkosten
                            };
                        }, Promise.resolve({ stundenlohn: 0, fahrtkosten: 0 }));

                        console.log('Final ausgabenDetails for museum:', museum.name, ausgabenDetails);

                        return {
                            name: museum.name,
                            anzahl: museumTermine.length || 0,
                            einnahmen: einnahmen || 0,
                            stundenlohnGesamt: ausgabenDetails.stundenlohn,
                            fahrtkostenGesamt: ausgabenDetails.fahrtkosten,
                            ausgaben: ausgabenDetails.stundenlohn + ausgabenDetails.fahrtkosten,
                            gewinn: einnahmen - (ausgabenDetails.stundenlohn + ausgabenDetails.fahrtkosten)
                        };
                    }));

                // Calculate product stats (all bookings)
                const produktStats = produktIds
                    .map(id => {
                        const produkt = produkte.find(p => p.id === id);
                        if (produkt?.name?.toLowerCase().includes('blockierung')) {
                            return null;
                        }
                        const produktBestellungen = bestellungen.filter(b => b.produkt_id === id);
                        const produktTermine = termine.filter(t => 
                            produktBestellungen.some(b => b.id === t.bestellung_id) &&
                            new Date(t.vorschlagdatetime).getFullYear() === filters.year
                        );

                        const anzahl = produktTermine.length || 0;
                        if (anzahl < 3) return null;

                        return {
                            name: produkt?.name || 'Unbekannt',
                            anzahl
                        };
                    })
                    .filter((item): item is ProduktStat => item !== null)
                    .sort((a, b) => b.anzahl - a.anzahl)
                    .slice(0, 20);

                // Calculate product stats (only abgeschlossen)
                const produktStatsAbgeschlossen = produktIds
                    .map(id => {
                        const produkt = produkte.find(p => p.id === id);
                        if (produkt?.name?.toLowerCase().includes('blockierung')) {
                            return null;
                        }
                        const produktBestellungen = abgeschlosseneBestellungen.filter(b => b.produkt_id === id);
                        const produktTermine = abgeschlosseneTermine.filter(t => 
                            produktBestellungen.some(b => b.id === t.bestellung_id) &&
                            new Date(t.vorschlagdatetime).getFullYear() === filters.year
                        );

                        const anzahl = produktTermine.length || 0;
                        if (anzahl < 3) return null;

                        return {
                            name: produkt?.name || 'Unbekannt',
                            anzahl
                        };
                    })
                    .filter((item): item is ProduktStat => item !== null)
                    .sort((a, b) => b.anzahl - a.anzahl)
                    .slice(0, 20);

                // Calculate mitarbeiter stats with both metrics independently
                const mitarbeiterStats = await Promise.all(mitarbeiter.map(async m => {
                    // Get all status entries for this mitarbeiter
                    const { data: mitarbeiterStatus } = await dataProvider.getList('relmitarbeiterstatus', {
                        pagination: { page: 1, perPage: 1000 },
                        filter: {
                            mitarbeiter_id: m.id
                        }
                    });

                    // Get all related terminvorschlag entries
                    const terminvorschlagIds = mitarbeiterStatus.map(status => status.terminvorschlag_id);
                    const { data: mitarbeiterTermine } = await dataProvider.getMany('terminvorschlag', {
                        ids: terminvorschlagIds
                    });

                    // Filter termine for selected year
                    const termineThisYear = mitarbeiterTermine.filter(termin => 
                        new Date(termin.vorschlagdatetime).getFullYear() === filters.year
                    );

                    if (termineThisYear.length === 0) return null;

                    // Get status entries for this year's termine
                    const statusThisYear = mitarbeiterStatus.filter(status =>
                        termineThisYear.some(termin => termin.id === status.terminvorschlag_id)
                    );

                    // Get bestellungen for these termine to check if they are completed
                    const bestellungIds = [...new Set(termineThisYear.map(t => t.bestellung_id))];
                    const { data: bestellungen } = await dataProvider.getMany('bestellung', {
                        ids: bestellungIds
                    });

                    // Metrik 1: Angenommene Termine (für Balkendiagramm)
                    const angenommeneStatus = statusThisYear.filter(status => status.angenommen);
                    const angenommeneTermine = angenommeneStatus.length;

                    // Zusätzlich: Angenommene UND abgeschlossene Termine
                    const angenommeneAbgeschlosseneTermine = angenommeneStatus.filter(status => {
                        const termin = termineThisYear.find(t => t.id === status.terminvorschlag_id);
                        const bestellung = bestellungen.find(b => b.id === termin?.bestellung_id);
                        return bestellung?.status === 'abgeschlossen';
                    }).length;

                    // Metrik 2: Bestätigungsquote (für Detailansicht) - unverändert
                    const alleAnfragen = statusThisYear.length;
                    const bestaetigteAnfragen = statusThisYear.filter(status => status.bestaetigt).length;

                    return {
                        id: m.id,
                        name: `${m.vorname} ${m.nachname}`,
                        anzahl: angenommeneTermine,  // Für Balkendiagramm: Angenommene Termine
                        anzahlAbgeschlossen: angenommeneAbgeschlosseneTermine,  // Für Balkendiagramm mit Filter
                        anzahlBestaetigt: bestaetigteAnfragen,  // Für Detailansicht
                        anzahlAnfragen: alleAnfragen  // Für Detailansicht
                    };
                })).then(stats => stats
                    .filter((stat): stat is NonNullable<typeof stat> => stat !== null)
                    .sort((a, b) => b.anzahl - a.anzahl)
                );

                // Calculate bezirk stats
                const bezirkCount: Record<string, { count: number, ausgaben: number }> = {
                    'Enzkreis': { count: 0, ausgaben: 0 },
                    'Stadt': { count: 0, ausgaben: 0 },
                    'Andere': { count: 0, ausgaben: 0 }
                };
                
                // Filter for abgeschlossene bestellungen with bezirk
                thisYearBestellungen.forEach(bestellung => {
                    // Only count bestellungen with a non-null bezirk value
                    if (bestellung.bezirk && bezirkCount[bestellung.bezirk] !== undefined) {
                        bezirkCount[bestellung.bezirk].count++;
                        
                        // For calculating ausgaben, only consider abgeschlossene bestellungen
                        if (bestellung.status === 'abgeschlossen') {
                            // Find the corresponding termin for this bestellung
                            const terminForBestellung = abgeschlosseneTermine.find(t => t.bestellung_id === bestellung.id);
                            
                            if (terminForBestellung) {
                                // Find related produkt to get the costs
                                const produkt = produkte.find(p => p.id === bestellung.produkt_id);
                                
                                if (produkt) {
                                    // Get mitarbeiter for this termin
                                    const mitarbeiterStatus = alleTerminvorschlaege
                                        .filter(t => t.id === terminForBestellung.id)
                                        .map(t => {
                                            return {
                                                mitarbeiter_id: t.mitarbeiter_id,
                                                fahrtkosten: mitarbeiter.find(m => m.id === t.mitarbeiter_id)?.fahrtkosten || 0
                                            };
                                        });
                                    
                                    // Calculate costs: stundenlohn + fahrtkosten
                                    const anzahlMitarbeiter = mitarbeiterStatus.length || 1;
                                    const dauer = produkt.dauer || 0;
                                    const stundenlohn = 50 * dauer * anzahlMitarbeiter;
                                    const fahrtkosten = mitarbeiterStatus.reduce((sum, m) => sum + m.fahrtkosten, 0);
                                    
                                    bezirkCount[bestellung.bezirk].ausgaben += (stundenlohn + fahrtkosten);
                                }
                            }
                        }
                    } else if (bestellung.bezirk) {
                        console.warn(`Unknown bezirk value: ${bestellung.bezirk}`);
                    }
                });
                
                const bezirkStats: BezirkStat[] = Object.entries(bezirkCount)
                    .filter(([_, data]) => data.count > 0) // Only include non-zero counts
                    .map(([name, data]) => ({ 
                        name, 
                        value: data.count,
                        ausgaben: data.ausgaben
                    }));
                
                console.log('Bezirk stats:', bezirkStats);

                const yearlyTotals = {
                    bestellungen: thisYearBestellungen.filter(b => b.status === 'abgeschlossen').length,
                    stundenlohn: museumStats.reduce((sum, museum) => sum + museum.stundenlohnGesamt, 0),
                    fahrtkosten: museumStats.reduce((sum, museum) => sum + museum.fahrtkostenGesamt, 0),
                    ausgaben: museumStats.reduce((sum, museum) => sum + museum.ausgaben, 0)
                };

                setStats(prev => ({
                    ...prev,
                    museumStats,
                    produktStats,
                    produktStatsAbgeschlossen,
                    mitarbeiterStats,
                    statusStats,
                    bezirkStats,
                    yearlyTotals
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setLoading(false);
        };

        if (filters.year) {
            fetchData();
        }
    }, [filters.year, dataProvider, museums, selectedMuseums, initialized]);

    return (
        <Box p={2}>
            <Typography variant="h4" gutterBottom>
                Analytics Dashboard
            </Typography>

            <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item xs={12} sm={6}>
                    <YearSelector 
                        filters={filters}
                        availableYears={availableYears}
                        setFilters={setFilters}
                    />
                </Grid>
                <StatsHighlight
                    title="Abgeschlossene Bestellungen"
                    value={stats.yearlyTotals.bestellungen.toString()}
                    unit={`im Jahr ${filters.year}`}
                />
                <StatsHighlight
                    title="Ausgaben"
                    value={
                        <AusgabenBreakdown 
                            stundenlohn={stats.yearlyTotals.stundenlohn || 0}
                            fahrtkosten={stats.yearlyTotals.fahrtkosten || 0}
                        />
                    }
                    unit=""
                />
            </Grid>

            <MuseumSelector
                museums={museums}
                selectedMuseums={selectedMuseums}
                setSelectedMuseums={setSelectedMuseums}
            />

            <Grid container spacing={2}>
                <MuseumStatsChart
                    title="Termine pro Museum"
                    dataKey="anzahl"
                    data={stats.museumStats}
                    color="#8884d8"
                    selectedMuseums={selectedMuseums}
                    infoText="Zeigt die Anzahl der abgeschlossenen Termine pro Museum an. Über die Checkboxen können Sie die angezeigten Museen filtern."
                />
                <MuseumStatsChart
                    title="Einnahmen pro Museum (€)"
                    dataKey="einnahmen"
                    data={stats.museumStats}
                    color="#82ca9d"
                    selectedMuseums={selectedMuseums}
                    infoText="Berechnung auf Basis abgeschlossener Bestellungen: Produktkosten × Anzahl der Teilnehmer:innen pro Termin"
                />
                <MuseumStatsChart
                    title="Ausgaben pro Museum (€)"
                    dataKey="ausgaben"
                    data={stats.museumStats}
                    color="#ff8042"
                    selectedMuseums={selectedMuseums}
                    infoText="Berechnung auf Basis abgeschlossener Bestellungen: (50€/Stunde × Dauer des Produkts × Anzahl zugewiesener Mitarbeiter:innen) + Fahrtkosten pro Mitarbeiter:in"
                />
                <TopProductsChart 
                    data={stats.produktStats} 
                    dataAbgeschlossen={stats.produktStatsAbgeschlossen}
                />
                <StatusPieChart data={stats.statusStats} />
                <BezirkPieChart data={stats.bezirkStats} />
                
                <Grid item xs={12}>
                    <TopMitarbeiterChart data={stats.mitarbeiterStats.slice(0, 10)} />
                </Grid>
                <Grid item xs={12}>
                    <MitarbeiterGrid
                        mitarbeiter={stats.mitarbeiterStats}
                        selectedMitarbeiterId={selectedMitarbeiterId}
                        onMitarbeiterSelect={setSelectedMitarbeiterId}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AnalyticsDashboard; 