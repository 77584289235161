import React, { useState, useEffect } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
import { Calendar, momentLocalizer, Views, View } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Switch, FormControlLabel, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Cookies from 'js-cookie';
import { bundeslaender, fetchSchoolHolidays, fetchPublicHolidays, CalendarHolidayEvent } from '../services/holidayService';

moment.locale('de', {
  week: {
    dow: 1,
    doy: 4
  }
});

const localizer = momentLocalizer(moment);

interface CalendarEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  status: string;
  isBlockierung: boolean;
  isHoliday?: boolean;
  grund?: string;
  museumId: number;
  resourceId: number;
  bestellungId?: number;
  resourceTitle?: string;
}

interface TerminEvent extends CalendarEvent {
  bestellungId: number;
  isBlockierung: false;
}

interface BlockierungEvent extends CalendarEvent {
  grund: string;
  isBlockierung: true;
}

interface Resource {
  resourceId: number;
  resourceTitle: string;
  color?: string;
}

interface BlockierungPopupProps {
  event: CalendarEvent;
  onClose: () => void;
  onEdit: () => void;
  resources: Resource[];
}

const BlockierungPopup: React.FC<BlockierungPopupProps> = ({ event, onClose, onEdit, resources }) => {
  const resource = resources.find(r => r.resourceId === event.resourceId);
  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000
    }}>
      <h3>{event.title}</h3>
      <p>Museum: {resource?.resourceTitle || 'Unknown'}</p>
      <p>Start: {moment(event.start).format('DD.MM.YYYY HH:mm')}</p>
      <p>Ende: {moment(event.end).format('DD.MM.YYYY HH:mm')}</p>
      <p>Grund: {event.grund}</p>
      <button onClick={onEdit}>Bearbeiten</button>
      <button onClick={onClose}>Schließen</button>
    </div>
  );
};

// Add this interface for error handling
interface ApiError {
  name?: string;
  message?: string;
  status?: number;
  body?: any;
  stack?: string;
}

const DragAndDropCalendar = withDragAndDrop(Calendar);

const TerminKalender = () => {
  const [baseEvents, setBaseEvents] = useState<CalendarEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);
  const [showHolidays, setShowHolidays] = useState(true);
  const [showPublicHolidays, setShowPublicHolidays] = useState(true);
  const [holidays, setHolidays] = useState<CalendarEvent[]>([]);
  const [publicHolidays, setPublicHolidays] = useState<CalendarEvent[]>([]);
  const [selectedBundesland, setSelectedBundesland] = useState<string>('BW');
  const [resources, setResources] = useState<Resource[]>([]);
  const [currentView, setCurrentView] = useState<View>(Views.MONTH);
  
  // Add state for current date with cookie persistence
  const [currentDate, setCurrentDate] = useState<Date>(() => {
    const savedDate = Cookies.get('calendarDate');
    return savedDate ? new Date(savedDate) : new Date();
  });
  
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  // Save current date to cookie when it changes
  useEffect(() => {
    Cookies.set('calendarDate', currentDate.toISOString(), { expires: 30 });
  }, [currentDate]);

  // Save current view to cookie when it changes
  useEffect(() => {
    Cookies.set('calendarView', currentView, { expires: 30 });
  }, [currentView]);

  // Load view from cookie on initial render
  useEffect(() => {
    const savedView = Cookies.get('calendarView');
    if (savedView && (savedView === 'month' || savedView === 'week' || savedView === 'day')) {
      setCurrentView(savedView as View);
    }
  }, []);

  // Fetch museums to use as resources
  useEffect(() => {
    const fetchMuseums = async () => {
      try {
        const { data: museen } = await dataProvider.getList('museum', {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'name', order: 'ASC' },
        });
        
        setResources(museen.map(museum => ({
          resourceId: museum.id,
          resourceTitle: museum.name,
          color: museum.color
        })));
      } catch (error) {
        console.error('Error fetching museums:', error);
      }
    };
    fetchMuseums();
  }, [dataProvider]);

  // Fetch holidays
  useEffect(() => {
    const loadSchoolHolidays = async () => {
      const schoolHolidays = await fetchSchoolHolidays(selectedBundesland);
      setHolidays(schoolHolidays);
    };

    loadSchoolHolidays();
  }, [selectedBundesland]);

  // Fetch public holidays
  useEffect(() => {
    const loadPublicHolidays = async () => {
      const holidays = await fetchPublicHolidays(selectedBundesland);
      setPublicHolidays(holidays);
    };

    loadPublicHolidays();
  }, [selectedBundesland]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const [
          { data: termine },
          { data: blockierungen },
          { data: bestellungen },
          { data: produkte }
        ] = await Promise.all([
          dataProvider.getList('terminvorschlag', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'vorschlagdatetime', order: 'ASC' },
            filter: { status: 'akzeptiert' },
          }),
          dataProvider.getList('blockierung', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'start', order: 'ASC' },
          }),
          dataProvider.getList('bestellung', {
            pagination: { page: 1, perPage: 1000 },
          }),
          dataProvider.getList('produkt', {
            pagination: { page: 1, perPage: 1000 },
          }),
        ]);

        // Create lookup maps
        const bestellungenMap = new Map(bestellungen.map(b => [b.id, b]));
        const produkteMap = new Map(produkte.map(p => [p.id, p]));

        // Process termine
        const termineEvents = termine
          .map(termin => {
            const bestellung = bestellungenMap.get(termin.bestellung_id);
            if (!bestellung || bestellung.status === 'storniert') return null;

            const produkt = produkteMap.get(bestellung.produkt_id);
            if (!produkt) return null;

            const startTime = moment.utc(termin.vorschlagdatetime).local().toDate();
            const endTime = moment.utc(termin.vorschlagdatetime).local().add(produkt.dauer, 'hours').toDate();

            return {
              id: termin.id,
              title: produkt.name,
              start: startTime,
              end: endTime,
              status: termin.status,
              bestellungId: bestellung.id,
              isBlockierung: false,
              resourceId: produkt.museum_id,
              museumId: produkt.museum_id
            };
          })
          .filter((event): event is TerminEvent => event !== null);

        // Process blockierungen
        const blockierungenEvents = blockierungen
          .map(blockierung => ({
            id: blockierung.id,
            title: blockierung.name,
            start: moment.utc(blockierung.start).local().toDate(),
            end: moment.utc(blockierung.ende).local().toDate(),
            status: 'blockierung',
            isBlockierung: true,
            grund: blockierung.grund,
            resourceId: blockierung.museum_id,
            museumId: blockierung.museum_id
          }));

        setBaseEvents([...termineEvents, ...blockierungenEvents]);
      } catch (err) {
        console.error('Error fetching events:', err);
      }
    };

    fetchEvents();
  }, [dataProvider]);

  const eventStyleGetter = (event: CalendarEvent) => {
    const resource = resources.find(r => r.resourceId === event.resourceId);
    
    if (event.isHoliday) {
      if (event.status === 'publicHoliday') {
        return {
          style: {
            backgroundColor: '#FF5733',  // A different color for public holidays
            borderRadius: '5px',
            border: '2px dashed #990000',
            color: 'black',
            fontWeight: 'bold'
          },
        };
      }
      return {
        style: {
          backgroundColor: '#FFD700',
          backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255,255,255,.3) 10px, rgba(255,255,255,.3) 20px)',
          color: 'black',
          fontWeight: 'bold'
        },
      };
    }
    if (event.isBlockierung) {
      return {
        style: {
          backgroundColor: 'red',
          backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255,255,255,.5) 10px, rgba(255,255,255,.5) 20px)',
          color: 'white',
          fontWeight: 'bold'
        },
      };
    }
    return {
      style: {
        backgroundColor: resource?.color || '#3174ad'
      },
    };
  };

  const handleEventClick = (event: CalendarEvent) => {
    if (event.isBlockierung) {
      setSelectedEvent(event);
    } else if (event.bestellungId) {
      redirect('edit', 'bestellung', event.bestellungId);
    }
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  const handleEditBlockierung = () => {
    if (selectedEvent) {
      // Redirect directly to the blockierung edit page
      redirect('edit', 'blockierung', selectedEvent.id);
      setSelectedEvent(null); // Close the popup after redirecting
    }
  };

  // Combine all events based on filter settings
  const getAllEvents = () => {
    let allEvents = [...baseEvents];
    
    if (showHolidays) {
      allEvents = [...allEvents, ...holidays];
    }
    
    if (showPublicHolidays) {
      allEvents = [...allEvents, ...publicHolidays];
    }
    
    return allEvents;
  };

  return (
    <div style={{ 
      minHeight: 'calc(100vh - 100px)',
      height: 'auto',
      width: '100%',
      maxWidth: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column' as const
    }}>
      <Box sx={{ 
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 2
      }}>
        <FormControlLabel
          control={
            <Switch
              checked={showHolidays}
              onChange={(e) => setShowHolidays(e.target.checked)}
              name="showHolidays"
              color="primary"
            />
          }
          label="Ferien anzeigen"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showPublicHolidays}
              onChange={(e) => setShowPublicHolidays(e.target.checked)}
              name="showPublicHolidays"
              color="primary"
            />
          }
          label="Feiertage anzeigen"
        />
        {(showHolidays || showPublicHolidays) && (
          <FormControl size="small" sx={{ minWidth: 200 }}>
            <InputLabel id="bundesland-select-label">Bundesland</InputLabel>
            <Select
              labelId="bundesland-select-label"
              value={selectedBundesland}
              label="Bundesland"
              onChange={(e) => setSelectedBundesland(e.target.value)}
            >
              {bundeslaender.map((land) => (
                <MenuItem key={land.code} value={land.code}>
                  {land.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <div style={{ flex: 1, minHeight: '600px' }}>
        <DragAndDropCalendar
          localizer={localizer}
          events={getAllEvents()}
          startAccessor={(event: any) => event.start}
          endAccessor={(event: any) => event.end}
          eventPropGetter={(event: any) => eventStyleGetter(event as CalendarEvent)}
          onSelectEvent={(event: any) => handleEventClick(event as CalendarEvent)}
          views={{
            month: true,
            week: true,
            day: true
          }}
          date={currentDate}
          onNavigate={date => setCurrentDate(date)}
          defaultView={Views.MONTH}
          view={currentView}
          resources={currentView === Views.MONTH ? resources : undefined}
          resourceIdAccessor={(resource: any) => resource.resourceId}
          resourceTitleAccessor={(resource: any) => resource.resourceTitle}
          formats={{
            timeGutterFormat: 'HH:mm',
            eventTimeRangeFormat: ({ start, end }: { start: Date; end: Date }) => (
              `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`
            ),
          }}
          style={{ height: '100%' }}
          onView={(view: View) => setCurrentView(view)}
        />
      </div>
      {selectedEvent && (
        <BlockierungPopup 
          event={selectedEvent} 
          onClose={handleClosePopup} 
          onEdit={handleEditBlockierung}
          resources={resources}
        />
      )}
    </div>
  );
};

export default TerminKalender;