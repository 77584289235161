import React, { useState, useEffect, useCallback } from 'react';
import {
    useRecordContext,
    ReferenceManyField,
    ReferenceField,
    TextField,
    BooleanField,
    Datagrid,
    FunctionField,
    useListContext,
    useDataProvider,
} from 'react-admin';
import { LinearProgress, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AngenommenCheckbox, { CHECKBOX_CLICKED_EVENT } from './AngenommenCheckbox';
import moment from 'moment';
import { supabase } from '../supabaseClient';

interface ConflictingTermin {
    id: number;
    vorschlagdatetime: string;
    bestellung: {
        id: number;
        produkt: {
            name: string;
        };
    };
}

interface MitarbeiterConflicts {
    [mitarbeiterId: number]: ConflictingTermin[];
}

// Fügen Sie einen Interface für den Konflikt aus der Supabase-Funktion hinzu
interface ConflictResult {
    mitarbeiter_id: number;
    mitarbeiter_vorname: string;
    mitarbeiter_nachname: string;
    conflict_termin_id: number;
    conflict_bestellung_id: number;
    conflict_produkt_name: string;
    conflict_vorschlagdatetime: string;
    conflict_status: string;
    current_start: string;
    current_end: string;
    conflict_start: string;
    conflict_end: string;
}

const ZugewieseneMitarbeiter: React.FC = () => {
    const record = useRecordContext<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [mitarbeiterConflicts, setMitarbeiterConflicts] = useState<MitarbeiterConflicts>({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMitarbeiter, setSelectedMitarbeiter] = useState<{id: number, name: string} | null>(null);
    const dataProvider = useDataProvider();

    const fetchConflicts = useCallback(async () => {
        if (!record || !record.id) return;

        try {
            setIsLoading(true);
            
            // Aufruf der Supabase-Funktion get_mitarbeiter_conflicts
            const { data: conflicts, error } = await supabase
                .rpc('get_mitarbeiter_conflicts', { termin_id: record.id });
                
            if (error) {
                console.error('Fehler beim Abrufen der Konflikte:', error);
                setIsLoading(false);
                return;
            }
            
            // Umwandlung der Ergebnisse in das erwartete Format
            const formattedConflicts: MitarbeiterConflicts = {};
            
            // Gruppierung nach Mitarbeiter-ID
            conflicts.forEach((conflict: ConflictResult) => {
                if (!formattedConflicts[conflict.mitarbeiter_id]) {
                    formattedConflicts[conflict.mitarbeiter_id] = [];
                }
                
                formattedConflicts[conflict.mitarbeiter_id].push({
                    id: conflict.conflict_termin_id,
                    vorschlagdatetime: conflict.conflict_vorschlagdatetime,
                    bestellung: {
                        id: conflict.conflict_bestellung_id,
                        produkt: {
                            name: conflict.conflict_produkt_name
                        }
                    }
                });
            });
            
            setMitarbeiterConflicts(formattedConflicts);
        } catch (err) {
            console.error('Fehler in fetchConflicts:', err);
        } finally {
            setIsLoading(false);
        }
    }, [record]);

    useEffect(() => {
        fetchConflicts();
    }, [fetchConflicts]);

    useEffect(() => {
        const handleCheckboxClick = () => {
            setIsLoading(true);
            setTimeout(() => fetchConflicts(), 2500);
        };

        window.addEventListener(CHECKBOX_CLICKED_EVENT, handleCheckboxClick);

        return () => {
            window.removeEventListener(CHECKBOX_CLICKED_EVENT, handleCheckboxClick);
        };
    }, [fetchConflicts]);

    const handleOpenDialog = (event: React.MouseEvent, mitarbeiterId: number, mitarbeiterName: string) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedMitarbeiter({ id: mitarbeiterId, name: mitarbeiterName });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedMitarbeiter(null);
    };

    if (!record) return null;

    const ReferenceManyContent = () => {
        const { data, isLoading: listLoading } = useListContext();

        if (listLoading) {
            return (
                <Box sx={{ p: 2 }}>
                    <Typography>Loading...</Typography>
                </Box>
            );
        }

        return (
            <Datagrid data={data}>
                <ReferenceField source="mitarbeiter_id" reference="mitarbeiter" link={false}>
                    <FunctionField
                        render={(mitarbeiter: any) => (
                            <Box display="flex" alignItems="center">
                                <span>{`${mitarbeiter.nachname}, ${mitarbeiter.vorname}`}</span>
                                {mitarbeiterConflicts[mitarbeiter.id] && (
                                    <IconButton
                                        size="small"
                                        onClick={(event) => handleOpenDialog(event, mitarbeiter.id, `${mitarbeiter.nachname}, ${mitarbeiter.vorname}`)}
                                        sx={{ ml: 1, p: 0 }}
                                    >
                                        <NewReleasesIcon fontSize="small" color="warning" />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                    />
                </ReferenceField>
                <BooleanField source="bestaetigt" />
                <FunctionField
                    label="Angenommen"
                    render={(relRecord: any) => (
                        <AngenommenCheckbox record={relRecord} />
                    )}
                />
            </Datagrid>
        );
    };

    return (
        <div>
            {isLoading && <LinearProgress />}
            <Box sx={{ opacity: isLoading ? 0.5 : 1, pointerEvents: isLoading ? 'none' : 'auto' }}>
                <ReferenceManyField
                    label="Zugewiesene Mitarbeiter"
                    reference="relmitarbeiterstatus"
                    target="terminvorschlag_id"
                    filter={{ terminvorschlag_id: record.id }}
                >
                    <ReferenceManyContent />
                </ReferenceManyField>
            </Box>
            <Dialog 
                open={openDialog} 
                onClose={handleCloseDialog}
                onClick={(event) => event.stopPropagation()}
            >
                <DialogTitle>{`${selectedMitarbeiter?.name} hat für folgende Termine bereits zugesagt`}</DialogTitle>
                <DialogContent>
                    {selectedMitarbeiter && mitarbeiterConflicts[selectedMitarbeiter.id]?.map((termin) => (
                        <Typography key={termin.id}>
                            {`${termin.bestellung.produkt.name} - ${moment.utc(termin.vorschlagdatetime).local().format('DD.MM.YYYY HH:mm')}`}
                        </Typography>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Schließen</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ZugewieseneMitarbeiter;