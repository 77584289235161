import React, { useEffect, useState } from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    NumberField, 
    EditButton,
    SearchInput,
    FunctionField,
    useRecordContext,
    BooleanInput,
    SelectInput,
    useGetList,
    Form,
    FilterForm,
    ListBase,
} from 'react-admin';
import CustomDeleteButton from './CustomDeleteButton';
import moment from 'moment-timezone';
import ArchiveButton from './ArchiveButton';
import { Chip, Box, Paper, Typography, Fade, Zoom } from '@mui/material';

const CongratulationBanner = () => {
    const { data } = useGetList('bestellung', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'DESC' }
    });
    
    const [visible, setVisible] = useState(false);
    const highestId = data?.[0]?.id || 0;
    
    useEffect(() => {
        if (highestId >= 500 && highestId <= 510) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [highestId]);
    
    if (highestId >= 500 && highestId <= 510) {
        return (
            <Zoom in={visible} timeout={800}>
                <Paper 
                    elevation={6} 
                    sx={{
                        p: 3,
                        mb: 3, 
                        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                        color: 'white',
                        textAlign: 'center',
                        borderRadius: 3,
                        boxShadow: '0 8px 16px rgba(254, 107, 139, 0.3)',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <Box sx={{ 
                        position: 'absolute', 
                        top: -10, 
                        left: -10, 
                        right: -10, 
                        bottom: -10, 
                        opacity: 0.1, 
                        background: 'url("https://www.transparenttextures.com/patterns/cubes.png")'
                    }} />
                    
                    <Typography variant="h4" fontWeight="bold" sx={{ mb: 1 }}>
                        🎉 Herzlichen Glückwunsch! 🎉
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Wir haben {highestId} Bestellungen verarbeitet!
                    </Typography>
                    <Typography variant="body1">
                        Ein fantastischer Meilenstein für das Team!
                    </Typography>
                </Paper>
            </Zoom>
        );
    }
    
    return null;
};

const TagFilter = ({ alwaysOn }: { alwaysOn?: boolean }) => {
    const { data: products } = useGetList('produkt', {
        pagination: { page: 1, perPage: 1000 },
    });

    const uniqueTags = [
        { id: '', name: 'Keine Tags' },
        ...Array.from(new Set(
            products?.flatMap(product => product.tags || []) || []
        )).map(tag => ({ id: tag, name: tag }))
    ];

    return (
        <SelectInput
            source="tag"
            label="Tags filtern"
            choices={uniqueTags}
            alwaysOn={alwaysOn}
            defaultValue="Keine Tags"
            
        />
    );
};

const bestellungFilters = [
    <SearchInput source="q" alwaysOn placeholder="Suche nach Id" />,
    <SelectInput 
        source="status" 
        label="Status filtern"
        choices={[
            { id: null, name: 'Alle anzeigen' },
            { id: 'neu', name: 'Neu' },
            { id: 'machbar', name: 'Machbar' },
            { id: 'storniert', name: 'Storniert' },
            { id: 'abgeschlossen', name: 'Abgeschlossen' },
        ]}
        alwaysOn
    />,
    <TagFilter alwaysOn />
];

interface Terminvorschlag {
    id: number;
    bestellung_id: number;
    vorschlagdatetime: string;
    status: string;
}

interface Bestellung {
    id: number;
    kunde_id: number;
    produkt_id: number;
    anzahltn: number;
    sonderwuensche: string;
    status: string;
    created_at: string;
    produkt?: {
        id: number;
        name: string;
        tags: string[];
    };
    terminvorschlaege?: Terminvorschlag[];
}

const LocalDateField: React.FC<{ date: string }> = ({ date }) => {
    if (!date) return null;
    const localTime = moment.utc(date).local().format('DD.MM.YYYY HH:mm:ss');
    return <span>{localTime}</span>;
};

const ColorLegend = () => (
    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'lightgreen', marginRight: '5px' }}></div>
            <span>Mindestens 1 Match wurde gefunden</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'lightblue', marginRight: '5px' }}></div>
            <span>Ein Veranstaltungstermin wurde festgelegt</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'pink', marginRight: '5px' }}></div>
            <span>Älter als 48 Stunden ohne akzeptierten Termin</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: 'lightgray', marginRight: '5px' }}></div>
            <span>Stornierte Bestellung</span>
        </div>
    </div>
);

const TerminvorschlagField: React.FC = () => {
    const record = useRecordContext<Bestellung>();
    if (!record || !record.terminvorschlaege) {
        return <>-</>;
    }
    
    const matchingTerminvorschlag = record.terminvorschlaege.find(tv => tv.status === "akzeptiert");
    
    if (matchingTerminvorschlag) {
        return <LocalDateField date={matchingTerminvorschlag.vorschlagdatetime} />;
    } else {
        return <>-</>;
    }
};

const TagsField: React.FC = () => {
    const record = useRecordContext<any>();
    
    if (!record) {
        return null;
    }
    
    // Handle both data structures
    const tags = record.produkt?.tags || record.produkt_tags || [];
    
    return (
        <Box display="flex" gap={1} flexWrap="wrap">
            {tags.map((tag: string, index: number) => (
                <Chip 
                    key={index} 
                    label={tag} 
                    size="small" 
                    variant="outlined"
                />
            ))}
        </Box>
    );
};

const rowStyle = (record: Bestellung): React.CSSProperties => {
    if (!record) return {};
    
    if (record.status === 'storniert') {
        return { backgroundColor: 'lightgray' };
    }
    
    if (!record.terminvorschlaege) return {};
    
    const now = moment();
    const createdAt = moment(record.created_at);
    const isOlderThan48Hours = now.diff(createdAt, 'hours') > 48;
    
    const acceptedTerminvorschlag = record.terminvorschlaege.find(tv => tv.status === "akzeptiert");
    const matchingTerminvorschlag = record.terminvorschlaege.find(tv => tv.status === "match");
    
    if (isOlderThan48Hours && !acceptedTerminvorschlag) {
        return { backgroundColor: 'pink' };
    } else if (acceptedTerminvorschlag) {
        return { backgroundColor: 'lightblue' };
    } else if (matchingTerminvorschlag) {
        return { backgroundColor: 'lightgreen' };
    } else {
        return {};
    }
};

const isUnprocessed = (record: Bestellung): boolean => {
    if (!record) return false;
    if (record.status === 'storniert' || record.status === 'abgeschlossen') {
        return false;
    }
    if (!record.terminvorschlaege || record.terminvorschlaege.length === 0) {
        return true;
    }
    const acceptedTermin = record.terminvorschlaege.find(tv => tv.status === "akzeptiert");
    return !acceptedTermin;
};

interface BestellungListProps {
    filterValues?: {
        status?: string;
        tag?: string;
    };
}

export const BestellungList: React.FC<BestellungListProps> = (props) => (
    <ListBase {...props}>
        <>
            <CongratulationBanner />
            <ColorLegend />
            <List 
                {...props} 
                filters={bestellungFilters}
                filter={{ archived: false }}
            >
                <Datagrid rowClick="edit" rowStyle={rowStyle}>
                    <TextField source="id" />
                    <ReferenceField source="kunde_id" reference="kunde">
                        <TextField source="einrichtung" />
                    </ReferenceField>
                    <ReferenceField source="kunde_id" reference="kunde" label="Ansprechpartner">
                        <TextField source="ansprechpartner" />
                    </ReferenceField>
                    <ReferenceField source="produkt_id" reference="produkt">
                        <TextField source="name" />
                    </ReferenceField>
                    <TagsField />
                    <NumberField source="anzahltn" />
                    <TextField source="sonderwuensche" />
                    <TextField source="status" />
                    <FunctionField label="Terminvorschlag" render={TerminvorschlagField} />
                    <EditButton />
                    <ArchiveButton resource="bestellung" />
                </Datagrid>
            </List>
        </>
    </ListBase>
);

export default BestellungList;