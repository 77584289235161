import { DataProvider } from 'react-admin';
import { supabase } from './supabaseClient';

interface TagFilteredItem {
    id: number;
    produkt_id: number;
    produkt_name: string;
    produkt_tags: string[];
    [key: string]: any;  // for other fields returned by the function
}

const dataProvider: DataProvider = {
    getList: async (resource, params) => {
        const { page = 1, perPage = 10 } = params.pagination || {};
        const { field = 'id', order = 'ASC' } = params.sort || {};
        const { q, ansprechpartner_like, name_contains, einrichtung_like, tag, ...filters } = params.filter || {};
    
        if (resource === 'bestellung') {
            if (tag) {
                const { data: tagFilteredData, error: tagError } = await supabase
                    .rpc('get_bestellungen_by_tag', { search_tag: tag });

                if (tagError) throw tagError;

                const start = (page - 1) * perPage;
                const end = start + perPage;
                const paginatedData = tagFilteredData.slice(start, end);

                return {
                    data: paginatedData.map((item: TagFilteredItem) => ({
                        ...item,
                        id: item.id,
                        kunde_id: item.kunde_id,
                        produkt_id: item.produkt_id,
                        anzahltn: item.anzahltn,
                        sonderwuensche: item.sonderwuensche,
                        status: item.status,
                        created_at: item.created_at,
                        produkt: {
                            id: item.produkt_id,
                            name: item.produkt_name,
                            tags: item.produkt_tags || []
                        }
                    })),
                    total: tagFilteredData.length,
                };
            }

            let query = supabase
                .from(resource)
                .select('*', { count: 'exact' });
    
            // Apply search query for 'id'
            if (q) {
                const qNumber = parseInt(q, 10);
                if (!isNaN(qNumber)) {
                    query = query.eq('id', qNumber);
                } else {
                    return { data: [], total: 0 };
                }
            }
    
         // Apply other filters
         Object.entries(filters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                // Handle array values (e.g., for 'id in [...]' queries)
                query = query.in(key, value);
            } else if (typeof value === 'string' && value.includes(',')) {
                // Handle comma-separated string as array
                const arrayValue = value.split(',').map(v => v.trim());
                query = query.in(key, arrayValue);
            } else if (typeof value === 'string' && value.includes('%')) {
                query = query.ilike(key, value);
            } else if (typeof value === 'string' && value.toString().startsWith('ne.')) {
                // Handle not-equal filter with 'ne.' prefix
                const realValue = value.toString().substring(3); // Remove 'ne.' prefix
                query = query.neq(key, realValue);
            } else {
                query = query.eq(key, value);
            }
        });
            // Apply sorting and pagination
            const { data: bestellungen, error: bestellungError, count } = await query
                .order(field, { ascending: order === 'ASC' })
                .range((page - 1) * perPage, page * perPage - 1);
    
            if (bestellungError) throw bestellungError;
    
            // Fetch related terminvorschlaege
            const { data: terminvorschlaege, error: terminvorschlagError } = await supabase
                .from('terminvorschlag')
                .select('*')
                .in('bestellung_id', bestellungen.map(b => b.id));
    
            if (terminvorschlagError) throw terminvorschlagError;
    
            // Combine the data
            const data = bestellungen.map(bestellung => ({
                ...bestellung,
                terminvorschlaege: terminvorschlaege.filter(tv => tv.bestellung_id === bestellung.id)
            }));
    
            return {
                data: data || [],
                total: count || 0,
            };
        }
    
        // Start building the query
        let query = supabase
            .from(resource)
            .select('*', { count: 'exact' });
    
        // Apply search query for 'id'
        if (q) {
            const qNumber = parseInt(q, 10);
            if (!isNaN(qNumber)) {
                query = query.eq('id', qNumber);
            } else {
                // Optionally, handle the case where 'q' is not a number
                // For example, you could return no results or handle other fields
                // Here, we'll return no results if 'q' is not a number
                return { data: [], total: 0 };
            }
        }

        // Handle partial match for 'ansprechpartner'
        if (ansprechpartner_like) {
            query = query.ilike('ansprechpartner', `%${ansprechpartner_like}%`);
        }

        // Handle partial match for 'einrichtung'
        if (einrichtung_like) {
            query = query.ilike('einrichtung', `%${einrichtung_like}%`);
        }

        // Handle partial match for product name
        if (name_contains && resource === 'produkt') {
            query = query.ilike('name', `%${name_contains}%`);
        }
    
        // Apply other filters
        Object.entries(filters).forEach(([key, value]) => {
            if (typeof value === 'string' && value.includes('%')) {
                query = query.ilike(key, value);
            } else if (typeof value === 'string' && value.toString().startsWith('ne.')) {
                // Handle not-equal filter with 'ne.' prefix
                const realValue = value.toString().substring(3); // Remove 'ne.' prefix
                query = query.neq(key, realValue);
            } else {
                query = query.eq(key, value);
            }
        });
    
        // Apply sorting and pagination
        const { data, error, count } = await query
            .order(field, { ascending: order === 'ASC' })
            .range((page - 1) * perPage, page * perPage - 1);
    
        if (error) throw error;
    
        return {
            data: data || [],
            total: count || 0,
        };
    },

    // ... (all other methods remain unchanged)

    getOne: async (resource, params) => {
        const { data, error } = await supabase
            .from(resource)
            .select('*')
            .eq('id', params.id)
            .single();

        if (error) throw error;

        return { data: data || {} };
    },

    getMany: async (resource, params) => {
        const { data, error } = await supabase
            .from(resource)
            .select('*')
            .in('id', params.ids);

        if (error) throw error;

        return { data: data || [] };
    },

    getManyReference: async (resource, params) => {
        const { page = 1, perPage = 10 } = params.pagination || {};
        const { field = 'id', order = 'ASC' } = params.sort || {};

        const { data, error, count } = await supabase
            .from(resource)
            .select('*', { count: 'exact' })
            .eq(params.target, params.id)
            .range((page - 1) * perPage, page * perPage - 1)
            .order(field, { ascending: order === 'ASC' });

        if (error) throw error;

        return {
            data: data || [],
            total: count || 0,
        };
    },

    create: async (resource, params) => {
        if (resource === 'terminvorschlag') {
            params.data.vorschlagdatetime = new Date(params.data.vorschlagdatetime).toISOString();
        }

        const { data, error } = await supabase
            .from(resource)
            .insert(params.data)
            .select()
            .single();

        if (error) throw error;

        return { data: data || {} };
    },

    update: async (resource, params) => {
        if (resource === 'terminvorschlag' && params.data.vorschlagdatetime) {
            params.data.vorschlagdatetime = new Date(params.data.vorschlagdatetime).toISOString();
        }

        const { data, error } = await supabase
            .from(resource)
            .update(params.data)
            .eq('id', params.id)
            .select()
            .single();

        if (error) throw error;

        return { data: data || {} };
    },

    updateMany: async (resource, params) => {
        const { data, error } = await supabase
            .from(resource)
            .update(params.data)
            .in('id', params.ids)
            .select();

        if (error) throw error;

        return { data: params.ids };
    },

    delete: async (resource, params) => {
        const { data, error } = await supabase
            .from(resource)
            .delete()
            .eq('id', params.id)
            .single();

        if (error) {
            console.error('Error deleting resource:', error);
            throw error;
        }

        return { data: data || { id: params.id } };
    },

    deleteMany: async (resource, params) => {
        const { data, error } = await supabase
            .from(resource)
            .delete()
            .in('id', params.ids);

        if (error) {
            console.error('Error deleting multiple resources:', error);
            throw error;
        }

        return { data: params.ids };
    },
    // Add this method to handle custom authentication
    getAuthToken: async () => {
        const { data: { session } } = await supabase.auth.getSession();
        return session?.access_token;
    },

    // Add custom method for image upload
    uploadImage: async (productId: number, file: File) => {
        const fileName = `${productId}/${Date.now()}-${file.name}`;
        
        const { data: uploadData, error: uploadError } = await supabase.storage
            .from('product-images')
            .upload(fileName, file);

        if (uploadError) throw uploadError;

        const { data: imageData, error: dbError } = await supabase
            .from('produkt_images')
            .insert({
                produkt_id: productId,
                storage_path: fileName,
                display_order: 0
            })
            .select()
            .single();

        if (dbError) throw dbError;

        return imageData;
    },

    // Add method to get images for a product
    getProductImages: async (productId: number) => {
        const { data, error } = await supabase
            .from('produkt_images')
            .select('*')
            .eq('produkt_id', productId)
            .order('display_order');

        if (error) throw error;

        return data.map(img => ({
            ...img,
            url: supabase.storage
                .from('product-images')
                .getPublicUrl(img.storage_path).data.publicUrl
        }));
    },

    // Add method to delete image
    deleteImage: async (imageId: number, storagePath: string) => {
        try {
            // First verify the file exists
            const { data: fileExists } = await supabase.storage
                .from('product-images')
                .list(storagePath.split('/')[0]); // List files in the folder

            console.log('Files in folder:', fileExists);

            // Delete file from storage
            const { data, error: storageError } = await supabase.storage
                .from('product-images')
                .remove([storagePath]);

            if (storageError) {
                console.error('Storage deletion error:', storageError);
                throw storageError;
            }

            // Delete database record
            const { error: dbError } = await supabase
                .from('produkt_images')
                .delete()
                .eq('id', imageId);

            if (dbError) throw dbError;

            return { success: true };
        } catch (error) {
            console.error('Delete operation failed:', error);
            throw error;
        }
    }
};

export default dataProvider;